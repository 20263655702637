<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card" title="Tambah Ujian Kelas">
      <div class="mt-2">
        <b-row>
          <!-- Judul Ujian -->
          <b-col lg="12 mb-1">
            <label class="d-block">Judul Ujian</label>
            <b-form-input
              v-model="formData.taskTitle"
              placeholder="Masukkan Judul Ujian"
              :state="formErrors.taskTitle.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.taskTitle.state">
              {{ formErrors.taskTitle.message }}
            </b-form-invalid-feedback>
          </b-col>

          <!-- Deskripsi Ujian -->
          <b-col lg="12 mb-1">
            <label class="d-block">Detail Ujian</label>
            <b-form-textarea
              v-model="formData.taskDescription"
              placeholder="Masukkan Detail Ujian / Perintah Pengerjaan Ujian Disini"
              rows="3"
              no-resize
              :state="formErrors.taskDescription.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.taskDescription.state">
              {{ formErrors.taskDescription.message }}
            </b-form-invalid-feedback>
          </b-col>

          <!-- Ujian Berupa File -->
          <b-col lg="12 mb-1">
            <label class="d-block">Detail Ujian Berupa File (Opsional)</label>
            <div v-if="filelist.length === 0" class="drop-file-con">
              <b-card
                class="drop-file p-4 mt-2"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <div class="d-flex flex-column align-items-center">
                  <b-avatar size="lg" rounded="sm" variant="light-secondary">
                    <i class="card-icon-lg ti ti-file-upload"></i>
                  </b-avatar>
                  <h3 class="pt-1 small-gap-bottom">
                    Seret file di sini
                  </h3>
                  <span class="small-gap-bottom">
                    (Format file yang didukung: .pdf, .doc, .docx)
                  </span>
                  <span class="small-gap-bottom">
                    Maksimal 2MB
                  </span>
                  <b-button variant="primary" @click="$refs.file.click()">
                    Upload File
                  </b-button>
                </div>
              </b-card>
            </div>

            <!-- Display Selected File -->
            <b-row v-show="filelist.length != 0">
              <b-col lg="2">
                <b-card
                  class="border-light-secondary d-flex flex-column text-center"
                  v-for="(file, index) in filelist"
                  :key="index"
                >
                  <b-button
                    class="remove-button-x"
                    variant="danger"
                    size="sm"
                    @click="remove(index)"
                  >
                    <i class="ti ti-x"></i>
                  </b-button>
                  <b-avatar
                    rounded
                    :variant="fileType(getExtension(file.name))"
                  >
                    <span class="p-2"> {{ getExtension(file.name) }}</span>
                  </b-avatar>
                  <div class="mt-1">
                    <span>
                      {{ ellipsis(file.name) }}
                    </span>
                  </div>
                </b-card>

                <!-- Hidden Input -->
                <input
                  ref="file"
                  hidden
                  type="file"
                  accept=".pdf,.doc, .docx,.ppt, .pptx,.xls, .xlsx"
                  @change="onChange()"
                />
              </b-col>
            </b-row>
          </b-col>

          <!-- Start Date -->
          <b-col lg="6">
            <label class="d-block">
              Tanggal Mulai
              {{ selectedTaskType === 'submission' ? 'Ujian' : 'Kuis' }}
            </label>
            <b-form-group :state="!formErrors.taskStartDate.state">
              <flat-pickr
                v-model="formData.taskStartDate"
                class="form-control"
                :class="{
                  'is-invalid': formErrors.taskStartDate.state,
                }"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y (H:i)',
                  dateFormat: 'Y-m-d H:i',
                  minDate: 'today',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
            <div
              v-show="!formErrors.taskStartDate.state"
              class="invalid-feedback"
            >
              {{ formErrors.taskStartDate.message }}
            </div>
          </b-col>

          <!-- End Date -->
          <b-col lg="6">
            <label class="d-block">
              Tanggal Berakhir
              {{ selectedTaskType === 'submission' ? 'Ujian' : 'Kuis' }}
            </label>
            <b-form-group :state="!formErrors.taskEndDate.state">
              <flat-pickr
                v-model="formData.taskEndDate"
                class="form-control"
                :class="{
                  'is-invalid': formErrors.taskEndDate.state,
                }"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y (H:i)',
                  dateFormat: 'Y-m-d H:i',
                  minDate: 'today',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
            <div
              v-show="!formErrors.taskEndDate.state"
              class="invalid-feedback"
            >
              {{ formErrors.taskEndDate.message }}
            </div>
          </b-col>

          <!-- Jenis Ujian -->
          <b-col lg="12">
            <label class="d-block">Pilih Jenis Ujian</label>
            <b-row class="mt-0">
              <!-- File -->
              <b-col lg="4">
                <b-card
                  class="mt-1 card-material-type"
                  :class="
                    selectedTaskType === 'submission'
                      ? 'active-material-type border-primary'
                      : 'border-light-secondary'
                  "
                  @click="triggerFormInput('submission')"
                >
                  <div class="d-flex flex-column align-items-center p-2 ">
                    <b-avatar size="lg" rounded="sm" variant="light-primary">
                      <i class="card-icon-lg ti ti-file-upload"></i>
                    </b-avatar>
                    <h4 class="pt-1">
                      Unggah Ujian
                    </h4>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="selectedTaskType"
                      class="custom-control-primary"
                      value="submission"
                      plain
                    />
                  </div>
                </b-card>
              </b-col>

              <!-- Multiple Choice -->
              <b-col lg="4">
                <b-card
                  class="mt-1 card-material-type"
                  :class="
                    selectedTaskType === 'exam'
                      ? 'active-material-type border-primary'
                      : 'border-light-secondary'
                  "
                  @click="triggerFormInput('exam')"
                >
                  <div class="d-flex flex-column align-items-center  p-2">
                    <b-avatar size="lg" rounded="sm" variant="light-success">
                      <i class="card-icon-lg ti ti-list"></i>
                    </b-avatar>
                    <h4 class="pt-1">
                      Kuis
                    </h4>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="selectedTaskType"
                      class="custom-control-primary"
                      value="exam"
                      plain
                    />
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <!-- Task Format Input -->
          <b-col v-if="selectedTaskType === 'submission'" lg="12">
            <label class="d-block">Format Ujian</label>
            <div>
              <b-form-radio v-model="taskFileExtension" plain value="file">
                File (Dokumen / Video)
              </b-form-radio>
              <b-form-radio v-model="taskFileExtension" plain value="url">
                Link (Google Drive / Youtube)
              </b-form-radio>
              <b-form-radio v-model="taskFileExtension" plain value="text">
                Essay
              </b-form-radio>
            </div>
          </b-col>

          <!-- Exam Format Input-->
          <b-col lg="6" class="mb-1" v-if="selectedTaskType === 'exam'">
            <label class="d-block">Durasi Kuis (Menit)</label>
            <div class="d-flex align-items-center">
              <b-form-input
                type="number"
                placeholder="Masukkan Durasi Kuis"
                v-model="formData.taskDuration"
                :state="formErrors.taskDuration.state"
              />
              <span class="ml-1">Menit</span>
            </div>
            <b-form-invalid-feedback v-show="!formErrors.taskDuration.state">
              {{ formErrors.taskDuration.message }}
            </b-form-invalid-feedback>
          </b-col>

          <b-col lg="12" v-if="selectedTaskType === 'exam'">
            <label class="d-block">Format Kuis</label>
            <div>
              <b-form-radio v-model="selectedQuizType" plain value="multiple">
                Pilihan Ganda
              </b-form-radio>
              <b-form-radio v-model="selectedQuizType" plain value="essay">
                Essay
              </b-form-radio>
            </div>
          </b-col>
        </b-row>

        <div class="mt-1">
          <b-button variant="primary" class="float-right " @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i
                v-show="!isLoading"
                class="ti ti-device-floppy small-gap-right"
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'

import client from '@/libs/http/axios-config'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BFormTextarea,
    BSpinner,
    flatPickr,
  },
  data() {
    return {
      // state
      isLoading: false,
      selectedTaskType: 'submission',
      selectedQuizType: 'multiple',
      taskFileExtension: 'file',

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,

      // form data
      formErrors: {
        taskTitle: { state: null, message: '' },
        taskDescription: { state: null, message: '' },
        taskStartDate: { state: null, message: '' },
        taskEndDate: { state: null, message: '' },
        taskDuration: { state: null, message: '' },
      },
      formData: {
        taskTitle: '',
        taskDescription: '',
        taskStartDate: '',
        taskEndDate: '',
        taskDuration: 60,
      },

      // File Upload
      filelist: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    const { breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = {
      id: to.params.id,
      contentId: to.params.contentId,
    }
    to.meta.breadcrumb[breadcrumb.length - 3].params = { id: to.params.id }
    next()
  },
  mounted() {
    this.formData.taskStartDate = new Date().toISOString().slice(0, 10)
    this.formData.taskEndDate = new Date().toISOString().slice(0, 10)
  },
  methods: {
    avatarText,
    getExtension(filename) {
      return filename.split('.').pop()
    },
    ellipsis(text) {
      if (text.length > 6) {
        return `${text.substring(0, 10)}...${text.substr(
          text.lastIndexOf('.') + 1
        )}`
      }
      return text
    },
    fileType(type) {
      const filetype = {
        pdf: 'danger',
        doc: 'info',
        docx: 'info',
        ppt: 'warning',
        pptx: 'warning',
        xls: 'success',
        xlsx: 'success',
        mp4: 'secondary',
        mkv: 'info',
      }

      return filetype[type]
    },
    triggerFormInput(type) {
      this.selectedTaskType = type
    },

    // File Uploader
    onChange() {
      this.filelist = [...this.$refs.file.files]
    },
    remove(i) {
      this.filelist.splice(i, 1)
      const allDropFileContainer = document.querySelectorAll('.drop-file')

      allDropFileContainer.forEach((dropFile) => {
        dropFile.classList.remove('bg-drop-file')
      })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-drop-file')) {
        event.currentTarget.classList.add('bg-drop-file')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-drop-file')
    },
    drop(event) {
      event.preventDefault()
      const droppedFiles = event.dataTransfer.files

      if (droppedFiles.length > 1) {
        event.currentTarget.classList.remove('bg-drop-file')
        this.showToast(
          'danger',
          'XIcon',
          'Gagal',
          'File tidak boleh lebih dari satu'
        )
      } else {
        droppedFiles.forEach((element) => {
          const fileExtension = element.name.split('.').pop()
          const allowedExtension = [
            'pdf',
            'doc',
            'docx',
            'ppt',
            'pptx',
            'xls',
            'xlsx',
          ]

          if (allowedExtension.includes(fileExtension)) {
            this.filelist.push(element)
          } else {
            event.currentTarget.classList.remove('bg-drop-file')
            this.showToast('danger', 'XIcon', 'Gagal', 'File tidak didukung')
          }
        })
      }
    },

    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true
      if (this.formData.taskTitle === '') {
        this.formErrors.taskTitle = {
          state: false,
          message: 'Nama Ujian tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskTitle = {
          state: null,
          message: '',
        }
      }

      if (this.formData.taskDescription === '') {
        this.formErrors.taskDescription = {
          state: false,
          message: 'Deskripsi Ujian tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskDescription = {
          state: null,
          message: '',
        }
      }

      if (this.formData.taskStartDate === '') {
        this.formErrors.taskStartDate = {
          state: false,
          message: 'Tanggal Mulai Ujian tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskStartDate = {
          state: null,
          message: '',
        }
      }

      if (this.formData.taskEndDate === '') {
        this.formErrors.taskEndDate = {
          state: false,
          message: 'Tanggal Selesai Ujian tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskEndDate = {
          state: null,
          message: '',
        }
      }

      if (!isValid) {
        this.showToast('danger', 'XIcon', 'Error', 'Form tidak valid')
        this.isLoading = false
      } else {
        this.isLoading = true
      }

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        // Create form data
        const data = new FormData()
        data.append('name', this.formData.taskTitle)
        data.append('description', this.formData.taskDescription)
        data.append('start_date', this.formData.taskStartDate)
        data.append('end_date', this.formData.taskEndDate)
        data.append('is_exam', 1)

        // Check if material type is link
        if (this.selectedTaskType === 'submission') {
          data.append('type', 'submission')
          data.append('time', 0)
        } else {
          data.append('type', 'exam')
          data.append('time', this.formData.taskDuration)
        }

        // create content task
        await client
          .post(
            `/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks`,
            data
          )
          .then((response) => {
            const submissionData = new FormData()
            submissionData.append('question', this.formData.taskDescription)
            submissionData.append('type', this.taskFileExtension)

            if (this.filelist.length > 0) {
              submissionData.append('attachment', this.filelist[0])
            }

            // stop loading
            this.isLoading = false

            // redirect to quiz generation page
            if (this.selectedTaskType === 'exam') {
              const taskData = {
                task: response.data.data,
                quizType: this.selectedQuizType,
                generatedQuiz: [],
              }

              // set response to local storage
              localStorage.setItem('taskData', JSON.stringify(taskData))

              // show success toast
              this.showToast(
                'success',
                'CheckIcon',
                'Berhasil',
                'Ujian berhasil ditambah'
              )

              // redirect to quiz generation page (multiple / essay)
              if (this.selectedQuizType === 'multiple') {
                this.$router.push({
                  name: checkAuthorizeRole(
                    'classroom-detail-exam-generate-quiz-multiplechoice'
                  ),
                  params: {
                    id: this.classroomId,
                    contentId: this.contentId,
                    taskId: response.data.data.id,
                    task: response.data.data,
                  },
                })
              } else {
                this.$router.push({
                  name: checkAuthorizeRole(
                    'classroom-detail-exam-generate-quiz-essay'
                  ),
                  params: {
                    id: this.classroomId,
                    contentId: this.contentId,
                    taskId: response.data.data.id,
                    task: response.data.data,
                  },
                })
              }
            } else {
              // submit task file detail (question and task extension type : file, url, text)
              client
                .post(
                  `/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${response.data.data.id}/submissions`,
                  submissionData
                )
                .then((response) => {
                  // show success toast
                  this.showToast(
                    'success',
                    'CheckIcon',
                    'Berhasil',
                    'Ujian berhasil ditambah'
                  )

                  // redirect to classroom detail
                  this.$router.push({
                    name: checkAuthorizeRole('classroom-detail-exam'),
                    params: { id: this.classroomId, contentId: this.contentId },
                  })
                })
            }
          })
          .catch((error) => {
            this.isLoading = false

            // show success toast
            this.showToast(
              'danger',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.preview-material {
  max-height: 200px;
  object-fit: fill;
}

.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 2rem;
}

.vertical-scrollable > .row {
  position: relative;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-y: scroll;
}

.active-material-type {
  border-width: 2px !important;
}

.drop-file {
  cursor: pointer;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 2px dashed #d3d3d3 !important;
  border-radius: 0.428rem !important;
}
.card-material-type {
  cursor: pointer;
}

.remove-button-x {
  padding: 10px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.bg-drop-file {
  background-color: #c9ffe7 !important;
}
</style>
